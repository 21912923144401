<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">

      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <validation-observer ref="simpleRules">

          <b-form @submit.prevent>
            <b-card
              no-body
              class="invoice-preview-card"
            >

              <b-card-body class="invoice-padding pb-0">
               
                <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                  <div>
                    <div class="logo-wrapper">
                      <img
                        src="@/assets/images/logo/logo.png"
                        width="200px"
                        height="auto"
                      >
                      <h3 class="text-primary invoice-logo" />
                    </div>
                    <b-card-text class="mb-0">
                      RUE EL KOUNAITRA 1-dar Chaabene El Fehri
                    </b-card-text>
                    <b-card-text class="mb-0">
                      gsr-cle1@hotmail.fr
                    </b-card-text>
                    <b-card-text class="mb-0">
                      +216 97 607 740
                    </b-card-text>
                    <b-card-text class="mb-0">
                      MF : 1082793F/A/M/000
                    </b-card-text>

                  </div>

                  <div class="invoice-number-datedate mt-md-0 mt-2">
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                      <h4 class="invoice-title">
                        Commande N°
                      </h4>
                      <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="invoice-data-id"
                          v-model="last"
                        />
                      </b-input-group>
                    </div>
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                      <h4 class="invoice-title">
                        Date
                      </h4>
                      <flat-pickr
                        v-model="invoiceData.date"
                        class="form-control invoice-edit-input"
                      />
                    </div>

                    <b-row class="mt-1">
                      <b-col>
                        <h4
                          style="color: black"
                          class="text-center"
                        >
                          Device
                        </h4></b-col>
                      <b-col>
                        <validation-provider
                          #default="{ errors }"
                          name="device"
                          rules="required"
                        >
                          <v-select
                            v-model="invoiceData.currency"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="currencies"
                            label="name"
                            input-id="invoice-data-client"
                            :clearable="false"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>

                        </validation-provider>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-card-body>
              <b-card-body>
                <b-form-group>
                  <div class="d-flex align-items-center mt-3">
                    <label class="mr-1 h4">Type</label>
                    <b-form-radio-group
                      v-model="type"
                    >
                      <b-form-radio
                        value="avec facture"
                        class="h3"
                      >
                        Avec facture
                      </b-form-radio>
                      <b-form-radio
                        value="sans facture"
                        class="h3"
                      >
                        Sans facture
                      </b-form-radio>

                    </b-form-radio-group>
                  </div>
                </b-form-group>
              </b-card-body>
              <hr class="invoice-spacing">
              <b-card-body
                class="invoice-padding pt-0"
              >
                <b-row>

                  <b-col
                    cols="12"
                    xl="6"

                    class="mb-lg-1"
                  >
                    <h6 class="mb-1">
                      Fournisseur
                    </h6>
                    <v-select
                      v-model="invoiceData.supplier"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="suppliers"
                      label="company_name"
                      input-id="invoice-data-client"
                      :clearable="false"
                    />

                  </b-col>
                  <feather-icon
                    size="16"
                    icon="XOctagonIcon"
                    class="cursor-pointer mt-4"
                    @click="invoiceData.supplier=null"
                  />
                </b-row>

                <div>
                  <div
                    ref="form"
                    class="repeater-form"
                    :style="{height: trHeight}"
                  >
                    <b-row
                      v-for="(item, index) in invoiceData.items"
                      :key="index"
                      ref="row"
                      class="pb-2"
                    >

                      <b-col cols="12">

                        <div class="d-none d-lg-flex">
                          <b-row class="flex-grow-1 px-1">
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              Qte
                            </b-col>
                            <b-col
                              cols="12"
                              lg="4"
                            >
                              Label
                            </b-col>

                            <b-col
                              v-if="invoiceData.currency ==='TND'"
                              cols="12"
                              lg="2"
                            >
                              Prix Achat
                            </b-col>
                            <b-col
                              v-else
                              cols="12"
                              lg="2"
                            >
                              Prix Unitaire {{ invoiceData.currency }}
                            </b-col>

                            <b-col
                              v-if="invoiceData.currency ==='TND'"
                              cols="12"
                              lg="1"
                            >
                              Total prix achat
                            </b-col>
                            <b-col
                              v-else
                              cols="12"
                              lg="1"
                            >
                              Total {{ invoiceData.currency }}
                            </b-col>
                            <b-col
                              v-if="invoiceData.currency ==='TND'"
                              cols="12"
                              lg="2"
                            >
                              Prix vente
                            </b-col>
                            <b-col
                              v-else
                              cols="12"
                              lg="2"
                            >
                              Prix Unitaire DNT
                            </b-col>
                            <b-col
                              v-if="invoiceData.currency ==='TND'"
                              cols="12"
                              lg="1"
                            >
                              Total prix vente
                            </b-col>
                            <b-col
                              v-else
                              cols="12"
                              lg="1"
                            >
                              Total DNT
                            </b-col>
                          </b-row>
                          <div class="form-item-action-col" />
                        </div>
                        <div class="d-flex border rounded">
                          <b-row class="flex-grow-1 p-2">
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">Qty</label>
                              <b-form-input
                                v-model="item.qty"
                                value="1"
                                type="number"
                                class="mb-2"
                              />
                            </b-col>
                            <b-col
                              cols="12"
                              lg="4"
                            >
                              <label class="d-inline d-lg-none">Labelle</label>
                              <b-form-input
                                v-if="item.product.name == ''"
                                v-model="item.product.name"
                                class="mb-2"
                              />
                              <v-select
                                v-else
                                v-model="item.product"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="products"
                                label="name"
                                :clearable="false"
                                class="mb-2 item-selector-title"
                                placeholder="Select Item"
                                @input="setSelected(item,index)"
                              />
                            </b-col>

                            <b-col
                              v-if="invoiceData.currency ==='TND'"
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">Prix</label>
                              <b-form-input
                                v-model="item.product.purchase_ttc"
                                type="number"
                                class="mb-2"
                              />
                            </b-col>
                            <b-col
                              v-else
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">Prix</label>
                              <b-form-input
                                v-model="item.product.price_currency"
                                type="number"
                                class="mb-2"
                              />
                            </b-col>

                            <b-col
                              v-if="invoiceData.currency ==='TND'"
                              cols="12"
                              lg="1"
                            >
                              <label class="d-inline d-lg-none">Prix</label>
                              <div
                                v-if="item.product.purchase_ttc"
                                class="mb-1"
                              >
                                <p>{{ (item.product.purchase_ttc * item.qty).toFixed(3) }} DT</p>

                              </div>
                            </b-col>

                            <b-col
                              v-else
                              cols="12"
                              lg="1"
                            >
                              <label class="d-inline d-lg-none">Prix</label>
                              <div
                                v-if="item.product.price_currency"
                                class="mb-1"
                              >
                                <p>
                                  {{ (item.product.price_currency * item.qty).toFixed(3) }} {{ invoiceData.currency }}
                                </p>
                              </div>
                            </b-col>
                            <b-col
                              v-if="invoiceData.currency ==='TND'"
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">Prix</label>
                              <b-form-input
                                v-model="item.product.sales_ttc"
                                type="number"
                                class="mb-2"
                              />
                            </b-col>
                            <b-col
                              v-else
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">Prix</label>
                              <b-form-input
                                v-model="item.product.purchase_ttc"
                                type="number"
                                class="mb-2"
                              />
                            </b-col>
                            <b-col
                              v-if="invoiceData.currency ==='TND'"
                              cols="12"
                              lg="1"
                            >
                              <label class="d-inline d-lg-none">Prix</label>
                              <div
                                v-if="item.product.sales_ttc"
                                class="mb-1"
                              >
                                <p>{{ (item.product.sales_ttc * item.qty).toFixed(3) }} DT</p>
                              </div>
                            </b-col>
                            <b-col
                              v-else
                              cols="12"
                              lg="1"
                            >
                              <label class="d-inline d-lg-none">Prix</label>
                              <div
                                v-if="item.product.purchase_ttc"
                                class="mb-1"
                              >
                                <p>{{ (item.product.purchase_ttc * item.qty).toFixed(3) }} DT</p>
                              </div>
                            </b-col>

                          </b-row>
                          <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                            <feather-icon
                              size="16"
                              icon="XIcon"
                              class="cursor-pointer"
                              @click="removeItem(index)"
                            />
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="primary"
                    @click="addNewItem"
                  >
                    Ajouter produit
                  </b-button>

                  <b-card-body class="invoice-padding pb-0">
                    <b-row>

                      <b-col
                        cols="12"
                        md="6"
                        class="mt-md-0 mt-3 d-flex align-items-center"
                        order="2"
                        order-md="1"
                      />

                      <b-col
                        cols="12"
                        md="6"
                        class="mt-md-6 d-flex justify-content-end"
                        order="1"
                        order-md="2"
                      >
                        <div class="invoice-total-wrapper">

                          <hr class="my-50">
                          <div
                            class="invoice-total-item"
                          >
                            <p
                              v-if="invoiceData.currency ==='TND'"
                              class="invoice-total-title"
                            >
                              Total prix achat
                            </p>
                            <p
                              v-else
                              class="invoice-total-title"
                            >
                              Total prix DNT
                            </p>
                            <p
                              v-if="totalTTC"
                              class="invoice-total-amount"
                            >
                              {{ totalTTC }}
                            </p>

                          </div>

                          <div
                            v-if="invoiceData.currency ==='TND'"
                            class="invoice-total-item"
                          >

                            <p
                              class="invoice-total-title"
                            >
                              Total prix vente
                            </p>
                            <p
                              v-if="totalSales"
                              class="invoice-total-amount"
                            >
                              {{ totalSales }}
                            </p>

                          </div>

                          <div
                            v-else
                            class="invoice-total-item"
                          >

                            <p
                              class="invoice-total-title"
                            >
                              Total prix en {{ invoiceData.currency }}
                            </p>
                            <p
                              v-if="totalCurrency"
                              class="invoice-total-amount"
                            >
                              {{ totalCurrency }}
                            </p>

                          </div>

                        </div></b-col></b-row>
                  </b-card-body>

                  <hr class="invoice-spacing">

                </div></b-card-body>
                 <b-card>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    block
                    @click="save"
                  >
                    Enregistrer
                  </b-button>
                </b-card>
                </b-card>
          </b-form>
        </validation-observer>
       
      </b-col>

     
    </b-row>
    <invoice-sidebar-add-new-customer />

  </section>

</template>

<script>
import { ref } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormInput, BInputGroup, BInputGroupPrepend, VBToggle, BFormRadio, BFormRadioGroup, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import InvoiceSidebarAddNewCustomer from './CreateSupplier.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    flatPickr,
    vSelect,
    InvoiceSidebarAddNewCustomer,
    BFormRadio,
    BFormRadioGroup,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],

  data() {
    return {
      last: null,
      currencies: ['TND', 'USD', 'EURO'],
      type: 'avec facture',
      suppliers: [],
      barcode: '',
      exist: false,
      required,
    }
  },
  computed: {
    totalTTC() {
      return this.invoiceData.items.reduce(
        (accumulator, item) => accumulator + item.product.purchase_ttc * item.qty,
        0,
      )
    },
    totalSales() {
      return this.invoiceData.items.reduce(
        (accumulator, item) => accumulator + item.product.sales_ttc * item.qty,
        0,
      )
    },
    totalCurrency() {
      return this.invoiceData.items.reduce(
        (accumulator, item) => accumulator + item.product.price_currency * item.qty,
        0,
      )
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    this.getLastOrder()
    this.getSuppliers()
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    async getLastOrder() {
      const { data } = await axiosIns.get('/purchase-orders/last/')
      this.last = data
    },
    async getSuppliers() {
      const { data } = await axiosIns.get('/suppliers/')
      this.suppliers = data
    },
    async getProducts() {
      const { data } = await axiosIns.get('/products/')

      this.itemsOptions = data
    },

    setSelected(value, index) {
      const f = this.invoiceData.items.filter(item => item.product === value)
      if (f.length > 1) {
        this.removeItem(index)
        // eslint-disable-next-line operator-assignment
        f[0].qty = f[0].qty + 1
      } else {
        f[0].qty = 1
      }
    },

    async refresh() {
      this.exist = false
    },

    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)

      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')}`
    },

    withTitle(quantity, index) {
      this.$swal({
        title: 'Stock Alerte',
        icon: 'warning',
        text: `Quantité demandé est superieur au stock       il vous reste que ${quantity} pièce`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.removeItem(index)
        }
      })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },

    async save() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          const date = new Date()
          const now = this.formatFn(date.toISOString())
          let supplier
          if (this.invoiceData.supplier) {
            supplier = this.invoiceData.supplier.id
          } else {
            supplier = null
          }

          const payload = {
            number: this.last,
            type: this.type,
            date: this.invoiceData.date,
            currency: this.invoiceData.currency,
            supplier,
            products: this.invoiceData.items,
            date_today: now,
          }

          try {
            await axiosIns.post('/purchase-orders/create/', payload)
            this.$router.push({ name: 'purchase-orders' })
            this.showToast('success', 'top-center', 'Bon de commande ajouté avec succés')
          } catch (e) {
            this.showToast('danger', 'top-center', e.toString())
          }
        } else {
          setTimeout(() => {
            this.showToast('danger', 'top-center', 'champs obligatoire')
          }, 1000)
        }
      })
    },

    async addNewItem(result) {
      // this.$refs.form.style.overflow = 'hidden'
      const itemFormBlankItem = {
        product: result,
        qty: 1,
      }
      this.invoiceData.items.push(JSON.parse(JSON.stringify(itemFormBlankItem)))
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
  setup() {
    const itemFormBlankItem = {
      product: {

      },
      qty: 1,
    }
    const orders = ref([])
    const current = new Date()

    const invoiceData = ref({
      id: '',
      supplier: null,
      date: `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
      note: '',
      currency: 'TND',
      image: null,
    })

    const products = ref([])
    axiosIns.get('/products/for-purchase/').then(response => {
      products.value = response.data
    })

    return {
      invoiceData,
      products,
      itemFormBlankItem,
      orders,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
